<template>
  <div class="invoiceEditEntp">
    <!-- <p>
          请您与企业财务人员核实,确保提供的发票信息与税务机关预留登记信息保持一致,以免影响发票的后续使用
      </p> -->
    <!-- 抬头类型 -->
    <van-cell-group>
      <van-field
        readonly
        clickable
        is-link
        input-align="right"
        label="抬头类型"
        :value="enterpriseType"
        placeholder="选择抬头类型"
        @click="entpPicker = true"
      />
      <van-popup v-model="entpPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="enterpriseTypeList"
          @cancel="entpPicker = false"
          @confirm="confirmsEntp"
        />
      </van-popup>
      <!-- 发票抬头-->
      <van-field
        v-model="invoiceLook"
        label="发票抬头"
        right-icon="edit"
        placeholder="请输入发票抬头"
        input-align="right"
      />
      <!-- 发票类型 -->
      <van-field
        readonly
        clickable
        is-link
        input-align="right"
        label="发票类型"
        :value="invoiceType"
        placeholder="选择发票类型"
        @click="invoicePicker = true"
      />
      <van-popup v-model="invoicePicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="invoicePicker = false"
          @confirm="confirmInvo"
        />
      </van-popup>
      <!-- 企业税号 -->
      <van-field
        v-model="entpNumber"
        label="企业税号"
        right-icon="edit"
        placeholder="请输入发票抬头"
        input-align="right"
      />
      <div  style="height:10px;background-color:#f7f8fa"></div>
      <!-- 开户银行 -->
      <van-field
        v-model="bank"
        label="开户银行"
        right-icon="edit"
        placeholder="请输入开户银行"
        input-align="right"
       
      />
      <!-- 开户账号 -->
      <van-field
        v-model="bankNumber"
        label="开户账号"
        right-icon="edit"
        placeholder="请输入开户账号"
        input-align="right"
      />

      <!-- 联系地址 -->
      <van-field
        v-model="address"
        label="联系地址"
        right-icon="edit"
        placeholder="请输入联系地址"
        input-align="right"
      />
      <!-- 联系电话 -->
      <van-field
        v-model="phone"
        label="联系电话"
        right-icon="edit"
        placeholder="请输入联系电话"
        input-align="right"
        clearable
      />
 <div  style="height:10px;background-color:#f7f8fa"></div>
      <!-- 设置默认按钮 -->
      <van-cell center title="设置默认发票信息">
        <template #right-icon>
          <van-switch v-model="checkedInfo" size="24" />
        </template>
      </van-cell>
    </van-cell-group>
    <!-- 选择 -->
    <van-cell class="select">
      <van-checkbox v-model="checked" shape="square"
        >我已阅读并同意</van-checkbox
      >
    </van-cell>

    <!-- 保存按钮 -->
    <van-button type="default" size="large">保存</van-button>
  </div>
</template>

<script>
import Vue from "vue";
import { Picker } from "vant";
import { Popup } from "vant";
import { Field } from "vant";
import { Cell, CellGroup } from "vant";
import { Switch } from "vant";
import { Button } from "vant";
import { Checkbox, CheckboxGroup } from "vant";

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Picker);
export default {
  data() {
    return {
      // 抬头类型
      enterpriseTypeList: ["个人", "企业", "普通"],
      enterpriseType: "",
      entpPicker: false,
      //   发票抬头
      invoiceLook: "",
      //   发票类型
      invoiceList: ["增值税专用发票", "发票", "普通发票"],
      invoiceType: "",
      invoicePicker: false,
      // 企业税号
      entpNumber: "",
      // 开户银行
      bank: "",
      // 开户账号
      bankNumber: "",
      // 联系地址
      address: "",
      // 联系电话
      phone: "",
      checkedInfo: true,
      checked: false,
    };
  },
  methods: {
    //   选择抬头类型
    confirmsEntp(enterpriseType) {
      console.log(enterpriseType);
      this.enterpriseType = enterpriseType;
      this.entpPicker = false;
    },
    //   选择发票类型
    confirmInvo(invoiceType) {
      this.invoiceType = invoiceType;
      this.invoicePicker = false;
    },
  },
};
</script>

<style >
.select {
  background-color: #f7f8fa;
}
</style>